<template>
  <UiIconBoxHorizontal
    v-if="isHorizontal"
    :title="props.header"
    :description="props.subheader"
    :boxes="props.icons"
    :button-link="props.buttonLink.href"
    :button-text="props.buttonText"
  />
  <UiIconBox
    v-else
    :title="props.header"
    :description="props.subheader"
    :boxes="props.icons"
    :button-link="props.buttonLink.href"
    :button-text="props.buttonText"
    :is-footer="isFooter"
  />
</template>

<script lang="ts" setup>
import type { IconBox } from '@ui/components/UiIconBox/UiIconBox.types'
import type { UiHeaderProps } from '@ui/components/UiHeader/useUiHeader'
import type { ButtonLink } from '../T3CeRawlplugIconbox/T3CeRawlplugIconbox.types'

interface T3CeRawlplugIconbox extends UiHeaderProps {
  icons: IconBox[]
  buttonLink?: ButtonLink | string
  buttonText?: string
  elementLayout: string
}

const props = withDefaults(defineProps<T3CeRawlplugIconbox>(), {
  buttonLink: '',
  buttonText: '',
})

const isHorizontal = computed((): boolean => {
  return props.elementLayout === 'horizontal'
})
const isFooter = computed((): boolean => {
  return props.elementLayout === 'footer'
})
</script>
